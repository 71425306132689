<template>
  <div v-if="userInfo != null">
    <van-row class="uheadbox" type="flex" justify="space-between">
      <van-col>
        <van-row align="center" type="flex" :gutter="10">
          <van-col class="avatar"><img :src="userInfo.avatarUrl"/></van-col>
          <van-col class="nickName">{{userInfo.nickName}}</van-col>
        </van-row>
      </van-col>
      <van-col class="t-r">
        <van-row>
          <van-col span="24" class="award">￥{{uinfo.award}}</van-col>
          <van-col span="24" class="action mt10">
            <van-row align="center" type="flex" :gutter="10" justify="end">
              <van-col v-if="uinfo.can_award>0"><van-button type="primary" size="small" @click="Apply">全部提现({{uinfo.can_award}})</van-button></van-col>
              <van-col v-if="uinfo.award2>0"><a class="button" @click="navigateTo">提现记录</a></van-col>
            </van-row>

          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <div class="tips">注：每天最多提现3次</div>
    <van-list v-model="loading" :finished="finished" finished-text="已经到底了" @load="onPageChange" class="commissionList">
      <van-cell v-for="item in dataList" :key="item.id">
        <template #title>
          <span class="font-color-gray">{{item.create_time}}</span>
        </template>
        <template #right-icon>
          <span class="font-color-green">+{{item.money}}</span>
        </template>
      </van-cell>
    </van-list>
    <global :hdid="hdid"/>
  </div>
</template>

<script>
  
  import {GetCommission,Gethduinfo,ApplyCash} from "./services/services";
  import global from './components/global'
  import {mapGetters, mapMutations} from "vuex";
  export default {
    name:'commission',
    components: {global},
    data() {
      return {
        hdid:undefined,
        uinfo:{},
        dataList:[],
        pagination:{
          pageIndex:1,
          pageSize:10,
          pageCount:0,
          total:0
        },
        loading:false,
        finished:false
      }
    },
    computed:{
      ...mapGetters(['snapshotuser','userInfo']),
    },
    created(){
      this.hdid=parseInt(this.$route.params.hdid)
    },
    mounted() {
      this.finduinfo()
      this.findList()
    },
    methods: {
      ...mapMutations(['sethduserInfo']),
      finduinfo(){
        Gethduinfo({hdid:this.hdid}).then(result => {
          if(result.data.code==1){
            this.uinfo=result.data.data
            this.sethduserInfo(this.uinfo)
          }else{
            this.$toast(result.data.msg)
          }
        })
      },
      findList(star){
        this.loading = true
        let params = {
          searchbean:{
            hdid:this.hdid
          },
          pageIndex:this.pagination.pageIndex,
          pageSize:this.pagination.pageSize
        }
        star=star==undefined?true:star
        GetCommission(params).then(result => {
          this.loading=false
          if(result.data.code==1){
            const {list,recordCount,pageCount} = result.data.data
            this.dataList = star?list:[...this.dataList,...list]
            this.pagination.total = recordCount
            this.pagination.pageCount=pageCount
            if(this.dataList.length>=recordCount){
              this.finished = true
            }
          }else{
            this.$toast(result.data.msg)
          }
        })
      },
      Apply(){
        this.$toast.loading({
          message: '正在提交...',
          forbidClick: true,
          overlay:false
        });
        ApplyCash({money:this.uinfo.can_award,hdid:this.hdid}).then(result => {
          if(result.data.code==1){
            this.finduinfo()
            this.$toast.success(result.data.msg)
          }else{
            this.$toast(result.data.msg)
          }
        })
      },
      navigateTo(){
        this.$router.push({path:'/wx/cash/'+this.hdid})
      },
      onPageChange() {
        if (this.pagination.pageIndex >= this.pagination.pageCount) {
          return false
        }
        this.pagination.pageIndex += 1
        this.findList(false)
      },
    }
  }
</script>
<style lang="less" scoped>
  .tips{padding:10px;color:#ec385b;}
.commissionList{}
</style>
